import axiosConfig from "../Application/Services/axios.js";

const notificationClient = axiosConfig.instantiateAxios({
    baseURL: '/api/v1/shared/'
});

export default {
    /**
     * Fetch all resources.
     * @returns {Promise<array|null>}
     */
    getNotifications(page, per_page) {
        return notificationClient.get('notifications', {
            params: {
                page,
                per_page
            }
        });
    },
    /**
     * Update the given resource.
     * @param {string} uuid
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateNotification: (uuid, attr) => notificationClient.put(`notifications/${uuid}`, attr),
    /**
     * Delete a resource.
     * @returns {Promise<>}
     */
    deleteNotification: id => notificationClient.delete(`notifications/${id}`),
};
