import types from "../Shared/storeTypes.js";
import PlanClient from "./planClient.js";
import constants from "../Application/constants.js";
import planClient from "./planClient.js";

const
    state = {
        [types.OUTPUT_PROFILES]: [],
        [types.OUTPUT_PROFILES_TOTAL]: 0,
        [types.OUTPUT_PROFILES_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
        [types.OUTPUT_PROFILES_DEFAULTS]: [],
    },
    mutations = {
        [types.OUTPUT_PROFILES](state, data) {
            state[types.OUTPUT_PROFILES] = data.data;
            state[types.OUTPUT_PROFILES_TOTAL] = data.total || 0;
            state[types.OUTPUT_PROFILES_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
        [types.OUTPUT_PROFILES_DEFAULTS](state, data) {
            state[types.OUTPUT_PROFILES_DEFAULTS] = data;
        },
    },
    getters = {
        /**
         * Return all output profiles.
         * @param {object} state
         * @return {object}
         */
        profiles: state => state[types.OUTPUT_PROFILES],
        /**
         * Return a specific output profile from state.
         * @param {object} state
         * @return {object}
         */
        profile: state => model => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.OUTPUT_PROFILES].filter(profile => profile.id === id).shift();
        },
        /**
         * Return the available DCP audio languages.
         * @param {object} state
         * @return {object}
         */
        audioLanguages: state => state[types.OUTPUT_PROFILES_DEFAULTS].audioLanguages,
        /**
         * Return the available DCP territories.
         * @param state
         * @return {*}
         */
        countries: state => state[types.OUTPUT_PROFILES_DEFAULTS].countries,
        /**
         * Return the available DCP subtitle languages.
         * @param state
         * @return {*}
         */
        subtitleLanguages: state => state[types.OUTPUT_PROFILES_DEFAULTS].subtitleLanguages,
        /**
         * Return the available DCP bitrates.
         * @param state
         * @return {*}
         */
        dcpBitrates: state => state[types.OUTPUT_PROFILES_DEFAULTS].dcpBitrates,
    },
    helpers = {
        shouldFetchAnew(parameters) {
            return parameters.refresh
                || parameters.sortBy
                || parameters.page !== state[types.OUTPUT_PROFILES_PAGE]
                || parameters.perPage !== state[types.PAGINATION_PER_PAGE];
        },
        /**
         * Cleanup data before storage on server.
         * @param {object} data
         * @returns {object}
         */
        sanitize(data) {
            data = Object.assign({}, data);

            data.audio_lang = Array.isArray(data.audio_lang) ? data.audio_lang : [data.audio_lang];
            data.subtitle_lang = Array.isArray(data.subtitle_lang) ? data.subtitle_lang : [data.subtitle_lang];

            data.name = data.name.trim();
            data.kdm = +data.kdm;

            delete data.id;
            delete data.created_at;
            delete data.updated_at;

            return data;
        },
    },
    actions = {
        /**
         * Fetch audio languages, countries, etc..
         * @see storage/app/data/languages.json
         * @param {function} commit
         * @param {function} getters
         * @param {boolean} asObject
         * @return Promise<object|array>
         */
        fetchDefaults({commit, getters}) {
            const defaults = getters.defaults;

            if (defaults) {
                return Promise.resolve(defaults);
            }

            return planClient
                .getOutputProfileDefaults()
                .then(response => {
                    let defaults = Object.freeze(response.data);

                    commit(types.OUTPUT_PROFILES_DEFAULTS, defaults);

                    return defaults;
                });
        },
        /**
         * Fetch the resource.
         * @description Output profiles are never paginated.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<array|null>}
         */
        index({commit, getters}, parameters = {}) {
            const profiles = getters.profiles;

            if (profiles.length && !helpers.shouldFetchAnew(parameters)) {
                return Promise.resolve(profiles);
            }

            return PlanClient
                .getOutputProfiles(parameters)
                .then(response => {
                    commit(types.OUTPUT_PROFILES, response.data);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const profile = getters.profile(id);

            if (profile) {
                return Promise.resolve(profile);
            }

            return PlanClient.getOutputProfile(id)
                .then(response => response.data ? response.data.data : null);
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        update({dispatch}, {id, attr}) {
            attr = helpers.sanitize(attr);

            return PlanClient.updateOutputProfile(id, attr)
                .then(response => {
                    dispatch('index', {refresh: true});

                    return response.data.data;
                });
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {object} attr
         * @return {Promise<object>}
         */
        store({dispatch}, attr) {
            attr = helpers.sanitize(attr);

            return PlanClient.storeOutputProfile(attr)
                .then(response => {
                    dispatch('index', {refresh: true});

                    return response.data.data;
                });
        },
        /**
         * Delete a resource and fetch the current page again.
         * @param {function} commit
         * @param {function} dispatch
         * @param {number} id
         * @return {Promise<null>}
         */
        destroy({dispatch}, id) {
            return PlanClient
                .deleteOutputProfile(id)
                .then(() => dispatch('index', {refresh: true}));
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
