<template>
    <v-sheet v-if="failedWorkflowJobLog.failed_job" class="panel-dcp-error pa-6 mb-6" rounded color="error">
        <panel-title title="Process error"/>
        <p>{{ failedWorkflowJobLog.failed_job.message }}</p>
    </v-sheet>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";

export default {
    mixins: [dataFormatMixin],
    components: {
        PanelTitle,
    },
    props: {
        workflowLog: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            failedWorkflowJobLog: this.workflowLog.workflow_job_logs.filter(log => log.failed_job_id).pop()
        }
    },
    methods: {
    }
}
</script>
