import store from "../../Application/store.js";

export default {
    methods: {
        initDcpDownload(attr) {
            if (attr.url_expires_at > (new Date()).toISOString()) {
                window.open(attr.url, '_blank');

                return;
            }

            store.dispatch('dcps/extendDownloadExpiration', attr.id)
                .then(attr => {
                    window.open(attr.url, '_blank');
                })

        },
        getBadgeColor(text) {
            switch (text) {
                case 'done':
                    return 'success';
                case 'failed':
                case 'cancelled':
                    return 'error';
                default:
                    return 'warning';
            }
        }
    },
}
