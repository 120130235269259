<template>
    <div v-if="attr" class="view-dcp">
        <v-row>
            <v-col cols="8">
                <dcp-title-panel :dcp="attr"
                                 :image="attr.image"/>

                <dcp-error-panel v-if="attr.workflow_log && (attr.workflow_log.failed || attr.workflow_log.cancelled)"
                                 :workflow-log="attr.workflow_log"/>

                <workflow-job-logs v-if="attr.workflow_log"
                                   :workflow-log="attr.workflow_log"
                                   :items="attr.workflow_log.workflow_job_logs"/>
            </v-col>

            <v-col cols="4">
                <dcp-status v-if="attr && attr.workflow_log"
                            :dcp="attr"
                            :workflow-log="attr.workflow_log"/>

                <dcp-preview-playlist :workflow-log="attr.workflow_log"
                                      :items="attr.previews"/>

                <dcp-metadata :dcp="attr"/>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import {VDateInput} from "vuetify/labs/VDateInput";
import Datepicker from "../../Shared/Components/Datepicker.vue";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import OutputProfilePreviewEmpty from "../../Plan/Components/OutputProfilePreviewEmpty.vue";
import WorkflowJobLogs from "../Components/WorkflowJobLogs.vue";
import DcpPreviewPlaylist from "../Components/DcpPreviewPlaylist.vue";
import DcpStatus from "../Components/DcpStatus.vue";
import DcpTitlePanel from "../Components/DcpTitlePanel.vue";
import DcpErrorPanel from "../Components/DcpErrorPanel.vue";
import types from "../../Shared/storeTypes.js";
import echo from "../../Application/Services/echo.js";
import DcpMetadata from "../Components/DcpMetadata.vue";

export default {
    mixins: [validationMixin, formHelperMixin],
    components: {
        DcpMetadata,
        DcpErrorPanel,
        DcpTitlePanel,
        DcpStatus,
        DcpPreviewPlaylist,
        WorkflowJobLogs,
        OutputProfilePreviewEmpty,
        PanelTitle,
        DcpStatusBadge,
        Datepicker,
        GenericViewTitle,
        VDateInput,
    },
    data() {
        return {
            team: this.$store.getters['team/current'],
        }
    },
    computed: {
        ...mapState('dcps', {
            attr: types.DCPS_CURRENT,
        }),
    },
    created() {
        const modelId = +this.$route.params.id;

        this.$store.dispatch('dcps/get', {id: modelId, force: true});

        this.workflowStepEvent = echo.subscribe('workflow.job.step', (e, store, router) => {
            console.log(`Running event for dcp ${e.dcp_id}`);

            if (modelId === +e.dcp_id) {
                store.dispatch('dcps/get', {id: e.dcp_id, force: true})
            }
        });
    },
    unmounted() {
        this.workflowStepEvent.unsubscribe();
    }
}
</script>
