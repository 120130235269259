<template>
    <v-chip :color="color" :text="text" variant="tonal"/>

</template>

<script>
import dcpHelperMixin from "../../Plan/Mixins/dcpHelperMixin.js";

export default {
    mixins: [dcpHelperMixin],
    props: {
        dcp: Object,
    },
    data() {
        return {
            text: this.setText(this.dcp.status, this.dcp.workflow_log.progress),
            color: this.getBadgeColor(this.dcp.status)
        };
    },
    methods: {
        setText(status, progress) {
            if (status === 'done' || status === 'failed' || status === 'cancelled') return status;

            return progress + '%';
        },
    }
}
</script>
