<template>
    <div class="view-login">
        <suite-logo class="mx-auto my-6 text-center" />

        <v-card
            class="mx-auto pa-12 pb-8"
            elevation="8"
            max-width="448"
            rounded="lg"
        >
            <div class="text-subtitle-1 text-medium-emphasis">Account</div>

            <v-text-field
                v-model="username"
                density="compact"
                placeholder="Email address"
                prepend-inner-icon="spx:IconUser"
                variant="outlined"
                :error="hasError"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                Password

                <a
                    class="text-caption text-decoration-none text-blue"
                    href="#"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Forgot login password?</a>
            </div>

            <v-text-field
                :append-inner-icon="'spx:IconEye' + (visible ? 'Off' : '')"
                :type="visible ? 'text' : 'password'"
                v-model="password"
                density="compact"
                placeholder="Enter your password"
                prepend-inner-icon="IconLock"
                variant="outlined"
                @click:append-inner="visible = !visible"
                :error="hasError"
            ></v-text-field>

            <v-btn class="mb-8"
                   color="blue"
                   size="large"
                   variant="tonal"
                   block
                   @click="login">
                Log In
            </v-btn>
        </v-card>
    </div>
</template>
<script>
import store from "../../Application/store.js";
import router from "../../Application/Router/index.js";
import SuiteLogo from "../../Shared/Components/SuiteLogo.vue";

export default {
    components: {SuiteLogo},
    data: () => ({
        visible: false,
        username: '',
        password: '',
        hasError: false,
    }),
    methods: {
        login() {
            store
                .dispatch('auth/login', {username: this.username, password: this.password})
                .then(() => {
                    // Redirect to the initially requested page
                    router.push(this.$route.query.redirect || '/dashboard')
                })
                .catch(error => {
                    // Wrong credentials or nonexistent user
                    if (error.response && error.response.status === 422) {
                        this.hasError = true;
                    }
                });
        }
    }
}
</script>
