<template>
    <div class="panel-workflow-job-logs">
        <v-sheet class="pa-6 mb-6" rounded>
            <panel-title title="Process timeline"
                         :sub-title="'The process started on ' + this.formatDate(workflowLog.created_at, 'DD MMM YYYY HH:mm:ss')"/>

            <v-timeline align="start" side="end" style="grid-template-columns: 0 min-content auto;">
                <v-timeline-item v-for="item in items"
                                 v-show="!item.skipped && !item.hidden"
                                 :dot-color="!item.failed_job_id ? 'accent' : 'error'"
                                 size="large">
                    <template v-slot:icon>
                        <v-icon icon="IconTimelineEventPlus"/>
                    </template>
                    <div class="d-flex">
                        <strong class="me-4">{{ this.formatDate(item.ended_at, 'DD MMM HH:mm:ss') }}</strong>
                        <div>
                            <div>
                                <span>{{ item.job_name }}</span>
                                <v-chip prepend-icon="IconClockHour1" color="orange" size="small" class="ml-3">
                                    {{ this.formatSeconds(item.milliseconds / 1000) }}
                                </v-chip>
                            </div>
                            <div :class="'text-caption ' + (!item.failed_job ? 'text-muted' : 'text-red')">
                                {{ item.failed_job ? item.failed_job.message : item.description }}
                            </div>
                        </div>
                    </div>

                </v-timeline-item>
            </v-timeline>

        </v-sheet>
    </div>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";

export default {
    mixins: [dataFormatMixin],
    components: {
        DcpStatusBadge,
        PanelTitle,
    },
    props: {
        workflowLog: {
            type: Object,
            default: {},
        },
        items: {
            type: Array,
            default: []
        }
    },
    data() {
        this.headers = [
            {key: 'job_name', title: 'Step'},
            {key: 'job_name', title: 'Step'},
            {key: 'queue', title: 'queue'},
            {key: 'started_at', title: 'Started on', value: item => this.formatDate(item.started_at, 'DD MMM YYYY HH:mm:ss')},
            {key: 'ended_at', title: 'Finished on', value: item => this.formatDate(item.ended_at, 'DD MMM HH:mm:ss')},
            {key: 'milliseconds', title: 'Duration', value: item => this.formatSeconds(item.milliseconds)},
        ];

        return {}
    },
    methods: {
    }
}
</script>
