<template>
    <div class="view-advertisement-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' advertisement'">
            <v-btn type="submit"
                   text="Save"
                   prepend-icon="spx:IconDeviceFloppy"
                   @click.prevent="submitForm"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">General</h5>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.name"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[slugServerError, $options.rules.required]"
                            @input="e => createMode ? checkSlugUniqueness(e, 'advertisements') : null">
                            <template #details>
                                Slug: {{ attr.slug }}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="attr.campaign_id"
                            :items="campaignsSelect"
                            @update:search="s => searchCampaigns(s)"
                            @update:model-value="setOutputProfile"
                            :rules="[$options.rules.required, serverError('campaign_id')]"
                            required
                            label="Campaign"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                </v-row>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-select
                            v-model="attr.rating"
                            :items="ratings"
                            :rules="[$options.rules.required, serverError('rating')]"
                            required
                            label="Rating"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            v-model="attr.output_profile_id"
                            :items="outputProfiles"
                            :rules="[$options.rules.required, serverError('output_profile_id')]"
                            required
                            label="Output profile"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           prepend-icon="spx:IconDeviceFloppy"
                           @click.prevent="submitForm">Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import slugGenerationMixin from "../Mixins/slugGenerationMixin.js";
import Datepicker from "../../Shared/Components/Datepicker.vue";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import planClient from "../planClient.js";

const defaults = {
    name: '',
    slug: '',
    rating: '',
};

export default {
    mixins: [validationMixin, slugGenerationMixin, formHelperMixin],
    components: {
        Datepicker,
        GenericViewTitle,
    },
    data() {
        return {
            outputProfiles: [],
            campaigns: [],
            campaignsSelect: [],
            ratings: [],
            attr: Object.assign({}, defaults),
        }
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = +this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }

        this.setOutputProfiles();
        this.setRatings();
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('advertisements/store', this.attr)
                : this.$store.dispatch('advertisements/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('Advertisement', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('advertisements/get', id)
                .then(advertisement => {
                    this.attr = Object.assign(this.attr, { ...advertisement});

                    this.setCampaigns(this.attr.campaign);
                });
        },
        /**
         * Fetch and map the output profiles to the vuetify select format.
         * @return void
         */
        setOutputProfiles() {
            this.$store
                .dispatch('outputProfiles/index')
                .then(profiles => {
                    this.outputProfiles = [].concat(profiles).map(p => ({
                        value: p.id,
                        title: p.name
                    }))
                });
        },
        /**
         * Search for campaign with the given term.
         * @return void
         */
        searchCampaigns(term) {
            planClient.searchCampaigns(term).then(campaigns => this.setCampaigns(campaigns.data.data));
        },
        /**
         * Assign the campaigns and mirror them to the vuetify `v-select` format.
         * @param {array} campaigns
         */
        setCampaigns(campaigns = []) {
            this.campaigns = Array.isArray(campaigns) ? campaigns : [campaigns];

            this.campaignsSelect = [].concat(this.campaigns).map(p => ({
                value: p.id,
                title: p.name
            }));
        },
        /**
         * Sets the output profile from the currently selected campaign.
         */
        setOutputProfile() {
            this.attr.output_profile_id = this.campaigns[0]
                ? this.campaigns[0].output_profile_id
                : '';
        },
        /**
         * Fetch and map the ratings defined by the team settings to the vuetify select format.
         * @todo implement from team settings
         * @return void
         */
        setRatings() {
            this.ratings = ['U', 'PG', '12A', '12', '15', '18'];
        }
    },
}
</script>
