export default async function auth({ to, store }) {
    if (store.getters['auth/user']) {
        return true;
    }

    return await store.dispatch('auth/getAuthUser')
        .then(() => true)
        .catch(response => {
            return {
                name: 'Login',
                query: {redirect: to.fullPath}
            };
        });
}
