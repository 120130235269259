<template>
    <v-row class="text-center" align="center" justify="center" no-gutters>
        <v-col cols="12">
            <v-icon icon="IconRouteX" size="x-large"/>
            <h2 class="mt-2">You're not authorized here</h2>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {
    },
}
</script>
