import guest from "./guest.js";
import auth from "./auth.js";
import permission from "./permission.js";
import subscribed from "./subscribed.js";

const middlewares = {
    guest,
    auth,
    permission,
    subscribed
}

/** Run all defined middleware for a route recursively. */
export default async function middlewarePipeline(context, routeMiddleware) {
    for (let i = 0; i < routeMiddleware.length; i++) {
        const resolvedMiddleware = resolveMiddleware(routeMiddleware, i);

        if (!resolvedMiddleware) {
            return true;
        }

        const result = await resolvedMiddleware.fn(context, resolvedMiddleware.arguments);

        if (result !== undefined && result !== true) return result;
    }
}

/** Resolve the middleware module and it's arguments. */
function resolveMiddleware(middleware, index) {
    const name = middleware[index];

    if (!name) return null;

    if (name.startsWith('can:')) {
        return {fn: middlewares.permission, arguments: resolveMiddlewareArgs(name)}
    }

    return {fn: middlewares[name], arguments: []};
}

/** Resolve Laravel-style middleware notation `name:arg1,arg2` */
function resolveMiddlewareArgs(name) {
    return name.split(':').pop().split(',');
}
