<template>
    <v-sheet class="panel-dcp-metadata pa-6 mb-6" rounded>
        <panel-title title="Metadata"/>
        <v-table density="compact">
            <tbody>
                <tr v-for="item in values">
                    <td class="pl-0">{{ item.label }}</td>
                    <td class="text-right pr-0">{{ item.value }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-sheet>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";

export default {
    mixins: [dataFormatMixin],
    components: {
        PanelTitle,
    },
    props: {
        dcp: {
            type: Object,
            default: {}
        },
    },
    watch: {
        dcp(attr) {
            this.values = this.setValues(attr)
        },
    },
    data() {
        return {
            values: this.setValues(this.dcp)
        }
    },
    methods: {
        setValues(attr) {
            return [
                {label: 'Projector aspect ratio', value: attr.projector_aspect_ratio ? attr.projector_aspect_ratio : '-'},
                {label: 'Frames', value: attr.frames ? attr.frames : '-'},
                {label: 'Seconds', value: attr.seconds ? attr.seconds : '-'},
                {label: 'FPS', value: attr.fps ? attr.fps : '-'},
                {label: 'Language', value: attr.language_spoken ? attr.language_spoken : '-'},
                {label: 'Subtitle language', value: attr.language_subtitles ? attr.language_subtitles : '-'},
                {label: 'Bitrate', value: attr.bitrate ? attr.bitrate : '-'},
                {label: 'Size unzipped', value: attr.bytes_unzipped ? this.formatDiskSize(attr.bytes_unzipped) : '-'},
                {label: 'Size zipped', value: attr.bytes_zipped ? this.formatDiskSize(attr.bytes_zipped) : '-'},
            ];
        }
    }
}
</script>
