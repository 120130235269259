export default async function guest({ store }) {
    if (!store.getters['auth/user']) {
        return await store.dispatch('auth/getAuthUser').then(() => {
            if (store.getters['auth/user']) {
                return { path: '/dashboard' };
            } else {
                return true;
            }
        }).catch(() => true);
    }

    return true;
}
