import axiosConfig from "../Application/Services/axios.js";

const UserClient = function (teamScoped = true) {
    this.axios = axiosConfig.instantiateAxios({baseURL: '/api/v1/'}, {teamScoped});
}

UserClient.prototype = {
    /**
     * Fetch all users.
     * @return {Promise<array|null>}
     */
    getUsers(parameters) {
        let params = {
            page: parameters.page,
            per_page: parameters.perPage,
        }

        if (parameters.sortBy) {
            params.sort_by = parameters.sortBy;
            params.sort = parameters.sort;
        }

        return this.axios.get('users', {params});
    },
    /**
     * Fetch all users' names.
     * @return {Promise<object|null>}
     */
    getUserNames() {
        return this.axios.get('users/names');
    },
    /**
     * Fetch a user.
     * @param {number} id
     * @returns {Promise<object|null>}
     */
    getUser(id) {
        return this.axios.get(`users/${id}`);
    },
    /**
     * Store the given data as a user.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeUser(attr) {
        return this.axios.post(`users`, attr);
    },
    /**
     * Update the given user.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateUser(id, attr) {
        return this.axios.put(`users/${id}`, attr);
    },
    /**
     * Delete a user.
     * @param {number} id
     * @returns {Promise<>}
     */
    deleteUser(id) {
        return this.axios.delete(`users/${id}`);
    },
};

export default UserClient;
