<template>
    <v-sheet class="panel-dcp-title pa-6 mb-6"
             rounded
             height="200px"
             :style="bgImgStyle">
        <v-row no-gutters class="h-100">
            <v-col align-self="end" cols="8">
                <h3>{{ dcp.name }}</h3>
                <p v-if="dcp.uuid">{{ dcp.uuid }}</p>
                <p>Output profile: <router-link :to="`/plan/output-profiles/${dcp.output_profile_id}`">{{ dcp.output_profile.name }}</router-link></p>
                <p>Campaign: <router-link :to="`/plan/campaigns/${dcp.campaign_id}`">{{ dcp.campaign.name }}</router-link></p>
            </v-col>
            <v-col align-self="end" cols="4" class="text-right">
                <v-btn v-if="dcp.path"
                       prepend-icon="IconCloudDownload"
                       style="background-color: transparent !important"
                       color="white"
                       variant="outlined"
                       density="default"
                       rounded="pill"
                       @click="() => initDcpDownload(dcp)">Download ({{ formatDiskSize(dcp.bytes_zipped) }})</v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import dcpHelperMixin from "../../Plan/Mixins/dcpHelperMixin.js";

export default {
    mixins: [dataFormatMixin, dcpHelperMixin],
    components: {
        GenericViewTitle,
        DcpStatusBadge,
        PanelTitle,
    },
    props: {
        dcp: {
            type: Object,
            default: {},
        },
        image: {
            type: String,
            default: ''
        }
    },
    watch: {
        image(path) {
            this.bgImgStyle = this.createBgStyle(path);
        }
    },
    data() {
        return {
            bgImgStyle: this.createBgStyle(this.dcp.image)
        }
    },
    methods: {
        createBgStyle(imagePath) {
            return {
                'background-image': `linear-gradient(45deg, rgba(19,171,219,0.7), #B10DC9), url('${imagePath}')`,
                'background-size' : '104%',
                'background-position': 'center',
                'background-repeat': 'no-repeat'
            }
        },
    }
}
</script>
