import types from "../Shared/storeTypes.js";
import constants from "../Application/constants.js";
import PrepareClient from "./prepareClient.js";

const
    state = {
        [types.DCPS]: [],
        [types.DCPS_CURRENT]: null,
        [types.DCPS_PAGE]: 1,
        [types.DCPS_TOTAL]: 0,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.DCPS](state, data) {
            state[types.DCPS] = data.data;
            state[types.DCPS_TOTAL] = data.total || 0;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
        [types.DCPS_CURRENT](state, attr) {
            state[types.DCPS_CURRENT] = attr;

            state[types.DCPS].map(dcp => (dcp.id !== attr.id ? dcp : attr));
        },
        [types.DCPS_TOTAL](state, count) {
            state[types.DCPS_TOTAL] = count;
        },
    },
    getters = {
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        page: state => state[types.DCPS_PAGE],
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        perPage: state => state[types.PAGINATION_PER_PAGE],
        /**
         * Return all DCPs.
         * @param {object} state
         * @return {array}
         */
        dcps: state => state[types.DCPS],
        /**
         * Return the total number of DCPs.
         * @param {object} state
         * @return {number}
         */
        total: state => state[types.DCPS_TOTAL],
        /**
         * Return a specific DCP from state.
         * @param {object} state
         * @return {object}
         */
        dcp: state => (model) => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.DCPS].filter(dcp => dcp.id === id).shift();
        },
    },
    actions = {
        /**
         * Verify if index page should be requested from server.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<object|null>}
         */
        index({commit, getters}, parameters = {}) {
            parameters.page = parameters.page || getters.page;

            parameters.perPage = parameters.perPage || getters.perPage;

            return PrepareClient
                .getDcps(parameters.page, parameters.perPage)
                .then(response => {
                    commit(types.DCPS, response.data);

                    commit(types.DCPS_TOTAL, response.data.total);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {function} commit
         * @param {number} id
         * @param {boolean} force
         * @return {Promise<object|null>}
         */
        get({getters, commit}, {id, force}) {
            console.log('dcps/get called');

            const dcp = getters.dcp(id);

            if (dcp && !force) {
                commit(types.DCPS_CURRENT, dcp)

                return Promise.resolve(dcp);
            }

            return PrepareClient.getDcp(id)
                .then(response => {
                    commit(types.DCPS_CURRENT, response.data?.data);

                    return response.data?.data;
                });
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {function} commit
         * @param {number} id
         * @return {Promise<object>}
         */
        extendDownloadExpiration({dispatch, commit}, id) {
            return PrepareClient.extendDcpDownloadExpiration(id)
                .then(response => {
                    commit(types.DCPS_CURRENT, response.data.data);

                    return response.data.data;
                });
        },
        /**
         * Delete a resource and fetch the current list again.
         * @param {function} dispatch
         * @param {function} getters
         * @param {function} commit
         * @param {object} model
         * @return {Promise<void>}
         */
        destroy({dispatch, getters, commit}, model) {
            return PrepareClient
                .deleteDcp(model)
                .then(() => {
                    commit(types.DCPS_TOTAL, getters.total - 1);

                    // Make sure the index view is reloaded.
                    dispatch('index');
                });
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
