<template>
    <div class="view-output-profiles">
        <generic-view-title title="Output Profiles">
            <div class="d-inline-block">
                <v-btn text="New"
                       prepend-icon="spx:IconFolderPlus"
                       :disabled="createDisabled"
                       @click="create"/>
                <v-tooltip v-if="createDisabled"
                           activator="parent"
                           location="bottom"
                           :text="createDisabledReason"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="profiles"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             hide-default-footer
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             rounded="lg"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/plan/output-profiles/${item.id}`">{{ item.name }}</router-link>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="IconEditCircle" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="IconTrash" @click.prevent="destroy(item)"/>
            </template>

            <template v-slot:bottom>
                <v-divider/>
                <div class="v-data-table-footer">
                    <div class="px-3 w-100">
                        Total: {{ this.profiles.length }}
                        {{ this.createDisabled ? '(maximum number of profiles reached)' : ''}}
                    </div>
                </div>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState, mapGetters} from "vuex";
import GenericListItem from "../Components/OutputProfileListItem.vue";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, dataFormatMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
        GenericListItem
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'created_at', title: 'Created on', value: item => `${this.formatDate(item.created_at)}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            createDisabled: false,
            createDisabledReason: '',
            team: this.$store.getters['team/current'],
        };
    },
    computed: {
        ...mapState('outputProfiles', {
            profiles: types.OUTPUT_PROFILES,
            total: types.OUTPUT_PROFILES_TOTAL,
        }),
        ...mapGetters('team', {
            teamCanCreateOutputProfiles: 'canCreateOutputProfiles'
        }),
    },
    methods: {
        seedTable(options) {
            this.handlePagination('outputProfiles/index', options)
                .then(() => this.allowProfileCreation());
        },
        create() {
            this.$router.push('/plan/output-profiles/create');
        },
        edit(profile) {
            this.$router.push(`/plan/output-profiles/${profile.id}`);
        },
        destroy(profile) {
            this.$store.dispatch('outputProfiles/destroy', +profile.id)
                .then(() => this.deletedMessage('Output profile'))
                .catch(e => this.handleHttpError(e));
        },
        allowProfileCreation() {
            if (!this.teamCanCreateOutputProfiles) {
                this.createDisabled = true;
                this.createDisabledReason = 'Upgrade you subscription to create more output profiles';
            }
        }
    }
}
</script>
