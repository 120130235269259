<template>
    <v-sheet class="panel-dcp-previews pa-6 pb-1 mb-6" rounded>
        <panel-title title="Previews"/>

        <div v-if="items && items[0]" class="mb-4">
            <video width="100%" controls>
                <source :src="items[0].url"/>
            </video>
        </div>

        <v-list v-if="items" density="compact" lines="two">
            <v-list-item v-for="(p, i) in items"
                         :key="p.id"
                         class="pl-0 pr-0 mb-4"
                         :title="p.codec + ' (' + p.size + ')'"
                         :subtitle="p.width + 'x' + p.height">

                <template v-slot:prepend>
                    <v-btn :href="p.url"
                           class="mr-3"
                           target="_blank"
                           icon="IconExternalLink"
                           rounded="pill"
                           variant="tonal"
                           color="green"/>
                </template>

                <!--<template v-slot:append>
                    <v-btn @click="(p) => (p)"
                           icon="IconX"
                           color="error" size="small" variant="tonal" rounded="sm" density="compact" />
                </template>-->
            </v-list-item>
        </v-list>

        <output-profile-preview-empty v-else msg="No preview videos yet"/>
    </v-sheet>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import OutputProfilePreviewEmpty from "../../Plan/Components/OutputProfilePreviewEmpty.vue";

export default {
    mixins: [dataFormatMixin],
    components: {
        OutputProfilePreviewEmpty,
        PanelTitle,
    },
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    data() {
        return {}
    },
    methods: {
    }
}
</script>
