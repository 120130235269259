import axios from 'axios';

let currentTeamId = null,
    routerInstance = null;

// Configure defaults

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.headers.common['X-Brightfish-UI'] = import.meta.env.VITE_APP_NAME_ABBR;

axios.defaults.withCredentials = true;

/**
 * Add the current team's ID to all requests using this interceptor.
 * @type {((function(*): {})|(function(*): void)|*)[]}
 */
const teamIdInterceptor = [
    function (config) {
        if (!currentTeamId) {
            return config;
        }

        if (config['method'].toUpperCase() === 'GET') {
            config.params = config.params || {};
            config.params.team_id = currentTeamId;
        } else {
            config.data = config.data || {};
            config.data.team_id = currentTeamId;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
];

/**
 * Handle API errors across all Axios instances.
 * @type {((function(*): *)|(function(*))|*)[]}
 */
const responseErrorInterceptor = [
    function (response) {
        return response;
    },
    async function (error) {
        const response = error?.response;

        if (response) {
            switch (response?.status) {
                case 404:
                    routerInstance.push({name: '404'})
                    break;
            }
        }

        return error;
    }
];

export default {
    instantiateAxios(axiosOptions = {}, spxOptions = {}) {
        const instance = axios.create(axiosOptions);

        if (spxOptions.teamScoped !== false) {
            instance.interceptors.request.use(...teamIdInterceptor);
        }

        instance.interceptors.response.use(...responseErrorInterceptor);

        return instance;
    },
    setCurrentTeam(teamId) {
        currentTeamId = teamId;
    },
    unsetCurrentTeam() {
        currentTeamId = null;
    }
}

/** Avoid importing vue-router directly in the module, which would result in a circular dependency. */
export function setupAxios(router) {
    routerInstance = router;
}
