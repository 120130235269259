<template>
    <div class="view-teams">
        <generic-view-title title="Teams">
            <div class="d-inline-block">
                <v-btn icon="spx:IconPlus" @click="create" density="comfortable" rounded="xl"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="teams"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             hide-default-footer
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             rounded="lg"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/admin/teams/${item.id}`">{{ item.name }}</router-link>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="IconEditCircle" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="IconTrash" @click.prevent="destroy(item)"/>
            </template>

            <template v-slot:bottom>
                <v-divider/>
                <div class="v-data-table-footer">
                    <div class="px-3 w-100">
                        Total: {{ this.total }}
                    </div>
                </div>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, dataFormatMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'created_at', title: 'Created on', value: item => `${this.formatDate(item.created_at)}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {

        };
    },
    computed: {
        ...mapState('team', {
            teams: types.TEAMS,
            total: types.TEAMS_TOTAL,
        }),
    },
    methods: {
        seedTable(options) {
            this.handlePagination('team/index', options);
        },
        create() {
            this.$router.push('/admin/teams/create');
        },
        edit(profile) {
            this.$router.push(`/admin/teams/${profile.id}`);
        },
        destroy(profile) {
            this.$store.dispatch('team/destroy', +profile.id)
                .then(() => this.deletedMessage('Team'))
                .catch(e => this.handleHttpError(e));
        },
    }
}
</script>
