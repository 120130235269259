<template>
    <div class="view-campaign-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' campaign'">
            <v-btn type="submit"
                   text="Save"
                   prepend-icon="spx:IconDeviceFloppy"
                   @click.prevent="submitForm"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">General</h5>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.name"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[slugServerError]"
                            @input="e => createMode ? checkSlugUniqueness(e, 'campaigns') : null">
                            <template #details>
                                Slug: {{ attr.slug }}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            v-model="attr.output_profiles"
                            :items="outputProfiles"
                            :rules="[serverError('output_profiles')]"
                            required
                            multiple
                            label="Output profiles"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Campaign calendar</h5>
                <v-row class="pt-4">
                    <v-col cols="4">
                        <datepicker
                            :model-value="attr.starts_on"
                            :rules="[$options.rules.isFuture, $options.rules.required, serverError('starts_on')]"
                            @update:modelValue="v => attr.starts_on = v"
                            label="Starts on"/>
                    </v-col>
                    <v-col cols="4">
                        <datepicker
                            :model-value="attr.ends_on"
                            :rules="[$options.rules.isFuture, compareWithStartsOn, serverError('ends_on')]"
                            @update:modelValue="v => attr.ends_on = v"
                            label="Ends on"/>
                    </v-col>
                    <v-col cols="4">
                        <datepicker
                            :disabled="true"
                            :rules="[$options.rules.isFuture]"
                            icon="spx:IconCalendarCode"
                            label="Campaign waves"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Advertiser & agency</h5>
                <v-row class="pt-4">
                    <v-col cols="6" class="pb-0">
                        <v-text-field
                            v-model="attr.advertiser_name"
                            :rules="[serverError('advertiser_name')]"
                            required
                            label="Advertiser name"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <v-combobox
                            v-model="attr.advertiser_emails"
                            :items="[]"
                            :rules="[$options.rules.isEmail, serverError('advertiser_emails')]"
                            chips
                            multiple
                            auto-complete="off"
                            label="Advertiser emails"
                            variant="outlined"
                            rounded="lg"
                        ></v-combobox>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.agency_name"
                            :rules="[serverError('agency_name')]"
                            label="Agency name"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-combobox
                            v-model="attr.agency_emails"
                            :items="[]"
                            :rules="[$options.rules.isEmail, serverError('agency_emails')]"
                            chips
                            multiple
                            auto-complete="off"
                            label="Agency emails"
                            variant="outlined"
                            rounded="lg"
                        ></v-combobox>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           prepend-icon="spx:IconDeviceFloppy"
                           @click.prevent="submitForm">Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import AppConstants from "../../Application/constants.js";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import slugGenerationMixin from "../Mixins/slugGenerationMixin.js";
import {VDateInput} from "vuetify/labs/VDateInput";
import Datepicker from "../../Shared/Components/Datepicker.vue";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

const defaults = {
    // general
    name: '',
    slug: '',
    // calendar
    starts_on: new Date(),
    ends_on: null,
    waves: null,
    // advertiser & agency
    advertiser_name: null,
    advertiser_emails: null,
    agency_name: null,
    agency_emails: null,
    // meta
    source: AppConstants.plan.campaigns.CAMPAIGN_SRC_MANUAL,
};

export default {
    mixins: [validationMixin, slugGenerationMixin, formHelperMixin],
    components: {
        Datepicker,
        GenericViewTitle,
        VDateInput,
    },
    data() {
        return {
            outputProfiles: [],
            attr: Object.assign({}, defaults),
        }
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = +this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }

        this.setOutputProfiles();
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('campaigns/store', this.attr)
                : this.$store.dispatch('campaigns/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('Campaign', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('campaigns/get', id)
                .then(campaign => (this.$data.attr = Object.assign(this.$data.attr, { ...campaign})));
        },
        /**
         * Fetch and map the output profiles to the vuetify select format.
         * @return void
         */
        setOutputProfiles() {
            this.$store
                .dispatch('outputProfiles/index')
                .then(profiles => {
                    this.outputProfiles = [].concat(profiles).map(p => ({
                        value: p.id,
                        title: p.name
                    }))
                });
        },
        /**
         * Higher order function for rule cannot be used, since it needs to compare
         * `starts_on`, which is reactive.
         * @param {Date|string} value
         * @returns {boolean|string}
         */
        compareWithStartsOn(value) {
            return this.$options.rules.notBefore(value, this.attr.starts_on);
        },
    }
}
</script>
