<template>
    <v-list-item :value="title">
        <template v-slot:prepend="{ active }">
            <v-list-item-action start>
                <v-checkbox-btn :model-value="active"></v-checkbox-btn>
            </v-list-item-action>
        </template>

        <v-list-item-title>{{ name }}</v-list-item-title>

        <v-list-item-subtitle>Folder {{ `/uploads/${team.slug}/${campaign_id}/${slug}/` }}</v-list-item-subtitle>

        <template v-slot:append="{ active }">
            <v-btn-secondary icon="IconTrash" @click.prevent="destroy()"/>
            <v-btn-secondary icon="IconEditCircle" @click.prevent="edit()"/>
        </template>

    </v-list-item>
</template>

<script>
export default {
    props: {
        profile: Object,
        team: Object,
    },
    data() {
        return this.profile;
    },
    methods: {
        edit() {
            this.$router.push({
                path: `/plan/output-profiles/${this.profile.id}`,
            })
        },
        destroy() {
            this.$store.dispatch('outputProfiless/destroy', this.profile);
        }
    },
}
</script>
