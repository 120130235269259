<template>
    <v-navigation-drawer :rail="hiding" :border="0" class="app-menu">
        <div class="d-flex" style="height: 80px; align-items: center; padding-left: 18px">
            <suite-logo :size="hiding ? 'icon' : 'normal'" align="left"/>
        </div>

        <v-list nav v-model:opened="open">
            <template v-for="(item, i) in items" :key="i">
                <v-list-item v-if="item.to && userCan(item.ability)"
                             :title="item.title"
                             :prepend-icon="item.icon"
                             :to="item.to"/>

                <v-list-group v-else-if="userCan(item.ability)" :value="item.title">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props"
                                     :title="item.title"
                                     :prepend-icon="item.icon"
                                     :to="item.to"/>
                    </template>

                    <v-list-item
                        v-for="(child, k) in item.children"
                        v-show="userCan(child.ability)"
                        :key="'c' + k"
                        :title="child.title"
                        :value="child.title"
                        :to="child.to"/>

                    <v-divider class="my-3" />
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";
import menuItems from "../../Shared/menuItems.js";
import SuiteLogo from "../../Shared/Components/SuiteLogo.vue";

export default {
    components: {
        SuiteLogo
    },
    props: ['show', 'user'],
    data() {
        this.items = menuItems;

        this.previousOpenItems = [];

        return {
            open: [], //menuItems.map(item => item.title),
            hiding: !this.show
        }
    },
    watch: {
        show(state) {
            this.hiding = !state;

            this.toggleSubMenus(this.hiding);
        },
    },
    computed: {
        ...mapGetters('auth', {
            userCan: 'userCan'
        }),
    },
    methods: {
        /** Close or re-open all submenus (used when toggling the drawer). */
        toggleSubMenus(hiding) {
            if (hiding) {
                this.previousOpenItems = [].concat(this.open);
                this.open = [];
            } else {
                this.open = [].concat(this.previousOpenItems);
                this.previousOpenItems = []
            }
        },
    }
}
</script>
