import types from "../Shared/storeTypes.js";
import constants from "../Application/constants.js";
import UserClient from "../Auth/UserClient.js";
import userStoreActions from "../Auth/userStoreActions.js";

const
    teamScopedUserClient = new UserClient(),
    state = {
        [types.TEAM_MEMBERS]: [],
        [types.TEAM_MEMBERS_TOTAL]: 0,
        [types.TEAM_MEMBERS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.TEAM_MEMBERS](state, data) {
            state[types.TEAM_MEMBERS] = data.data;
            state[types.TEAM_MEMBERS_TOTAL] = data.total || 0;
            state[types.TEAM_MEMBERS_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
    },
    getters = {
        /**
         * Return all members of the current user's team.
         * @param {object} state
         * @return {object}
         */
        users: state => state[types.TEAM_MEMBERS],
        /**
         * Return a specific team member from state.
         * @param {object} state
         * @return {object}
         */
        user: state => model => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.TEAM_MEMBERS].filter(user => user.id === id).shift();
        },
    },
    actions = userStoreActions(teamScopedUserClient, {
        type: types.TEAM_MEMBERS,
        typePage: types.TEAM_MEMBERS_PAGE,
        typeTotal: types.TEAM_MEMBERS_TOTAL,
    });

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
