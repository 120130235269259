import types from "../Shared/storeTypes.js";
import PlanClient from "./planClient.js";
import constants from "../Application/constants.js";

const
    state = {
        [types.CAMPAIGNS]: [],
        [types.CAMPAIGNS_TOTAL]: 0,
        [types.CAMPAIGNS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.CAMPAIGNS](state, data) {
            state[types.CAMPAIGNS] = data.data;
            state[types.CAMPAIGNS_TOTAL] = data.total || 0;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
        [types.CAMPAIGNS_TOTAL](state, count) {
            state[types.CAMPAIGNS_TOTAL] = count;
        },
    },
    getters = {
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        page: state => state[types.CAMPAIGNS_PAGE],
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {number}
         */
        perPage: state => state[types.PAGINATION_PER_PAGE],
        /**
         * Return all campaigns.
         * @param {object} state
         * @return {array}
         */
        campaigns: state => state[types.CAMPAIGNS],
        /**
         * Return the total number of campaigns.
         * @param {object} state
         * @return {number}
         */
        total: state => state[types.CAMPAIGNS_TOTAL],
        /**
         * Return a specific campaign from state.
         * @param {object} state
         * @return {object}
         */
        campaign: state => (model) => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.CAMPAIGNS].filter(campaign => campaign.id === id).shift();
        },
    },
    helpers = {
        /**
         * Cleanup data before storage on server.
         * @param {object} data
         * @returns {object}
         */
        sanitize(data) {
            data = Object.assign({}, data);

            data.name = data.name.trim();
            data.advertiser_name = data.advertiser_name.trim();
            data.agency_name = data.agency_name ? data.agency_name.trim() : null;

            delete data.id;
            delete data.created_at;
            delete data.updated_at;

            return data;
        },
        /**
         *
         * @param attr
         * @return {*}
         */
        format(attr) {
            attr.output_profiles = attr.output_profiles.map(p => p.id);

            return attr;
        }
    },
    actions = {
        /**
         * Verify if index page should be requested from server.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<object|null>}
         */
        index({commit, getters}, parameters = {}) {
            parameters.page = parameters.page || getters.page;

            parameters.perPage = parameters.perPage || getters.perPage;

            return PlanClient
                .getCampaigns(parameters.page, parameters.perPage)
                .then(response => {
                    commit(types.CAMPAIGNS, response.data);

                    commit(types.CAMPAIGNS_TOTAL, response.data.total);

                    return response.data.data.map(c => helpers.format(c));
                });
        },
        /**
         * Get total number of campaigns.
         * @param {function} commit
         * @param {function} getters
         * @return {Promise<number>}
         */
        count({commit, getters}) {
            const total = getters.total;

            if (!isNaN(total)) return Promise.resolve(total);

            return PlanClient.getCampaignCount()
                .then(response => {
                    commit(types.CAMPAIGNS_TOTAL, response.data.data);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const campaign = getters.campaign(id);

            if (campaign) {
                return Promise.resolve(campaign);
            }

            return PlanClient.getCampaign(id)
                .then(response => response.data ? helpers.format(response.data.data) : null);
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        update({dispatch}, {id, attr}) {
            attr = helpers.sanitize(attr);

            return PlanClient.updateCampaign(id, attr).then(response => helpers.format(response.data.data));
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {function} getters
         * @param {function} commit
         * @param {object} attr
         * @return {Promise<object>}
         */
        store({dispatch, getters, commit}, attr) {
            attr = helpers.sanitize(attr);

            return PlanClient.storeCampaign(attr)
                .then(response => helpers.format(response.data.data))
                .then(model => {
                    commit(types.CAMPAIGNS_TOTAL, getters.total + 1);
                    return model
                });
        },
        /**
         * Delete a resource and fetch the current list again.
         * @param {function} dispatch
         * @param {function} getters
         * @param {function} commit
         * @param {object} model
         * @return {Promise<void>}
         */
        destroy({dispatch, getters, commit}, model) {
            return PlanClient
                .deleteCampaign(model)
                .then(() => {
                    commit(types.CAMPAIGNS_TOTAL, getters.total - 1);

                    // Make sure the index view is reloaded.
                    dispatch('index');
                });
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
