<template>
    <v-sheet class="panel-dcp-status d-flex align-items-center justify-content-between pa-6 mb-6" rounded>
        <v-progress-circular :model-value="percentage" :rotate="360" :size="110" :width="15" color="success">
            <template v-slot:default>{{ percentage }} %</template>
        </v-progress-circular>
        <v-table density="compact" class="ml-6">
            <tbody>
            <tr v-for="item in values">
                <td class="px-0">{{ item.label }}</td>
                <td class="text-right pr-0">
                    <v-chip :text="item.value" :color="item.color" density="compact" variant="tonal"/>
                </td>
            </tr>
            </tbody>
        </v-table>
    </v-sheet>
</template>
<script>
import PanelTitle from "../../Shared/Components/PanelTitle.vue";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import dcpHelperMixin from "../../Plan/Mixins/dcpHelperMixin.js";

export default {
    mixins: [dataFormatMixin, dcpHelperMixin],
    components: {
        DcpStatusBadge,
        PanelTitle,
    },
    props: {
        dcp: {
            type: Object,
            default: {}
        },
        workflowLog: {
            type: Object,
            default: {}
        },
    },
    watch: {
        dcp(attr) {
            this.values = this.setValues(attr, this.workflowLog);

            this.percentage = this.workflowLog.progress;
        },
        workflowLog(log) {
            this.values = this.setValues(this.dcp, log);

            this.percentage = log.progress;
        },
    },
    data() {
        return {
            percentage: this.workflowLog.progress || 0,
            values: this.setValues(this.dcp, this.workflowLog)
        }
    },
    methods: {
        setValues(dcp, workflowLog) {
            return [
                {label: 'Pending jobs', value: workflowLog.pending_jobs + '/' + workflowLog.total_jobs, color: this.getBadgeColor(dcp.status)},
                {label: 'Status', value: dcp.status, color: this.getBadgeColor(dcp.status)},
                {label: 'Duration', value: workflowLog.seconds ? `${workflowLog.seconds}s` : '-', color: 'gray'},
                {label: 'Time end', value: workflowLog.finished_at ? this.formatDate(workflowLog.finished_at, 'DD MMM HH:mm') : '-', color: 'gray'},
            ];
        }
    }
}
</script>
