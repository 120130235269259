import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import types from "../../Shared/storeTypes.js";

let _uniqueId = 0,
    teamSubscribed = false;

const listeners = {
    'workflow.job.step': {}
};

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    namespace: '',
});


function onTeamDefined(teamId, store, router) {
    console.log(`Start listening to WorkflowJobEndEvent on team.${teamId}.workflows ...`);

    window.Echo.private(`team.${teamId}.workflows`)
        .listen('.workflow.job.step', (e) => {
            const keys = Object.keys(listeners['workflow.job.step']);

            console.log(`Caught workflow.job.step event, ${keys.length} subscribers to run`);

            keys.forEach(key => {
                console.log(`Running subscriber ${key}`);
                listeners['workflow.job.step'][key](e, store, router);
            });
        });
}


export default {
    setup(store, router) {
        store.subscribe((mutation, state) => {
            if (!teamSubscribed && mutation.payload && mutation.type === `team/${types.TEAM_CURRENT}`) {
                teamSubscribed = true;

                onTeamDefined(mutation.payload.id, store, router);
            }
        });
    },
    subscribe(event, cb) {
        const id = _uniqueId++;

        if (!listeners[event]) {
            listeners[event] = {};
        }

        listeners[event][id] = cb;

        console.log(`Subscribed ${id} to ${event}`);

        return {
            unsubscribe: () => {
                console.log(`Unsubscribing ${id} from ${event}`);

                delete listeners[event][id];

                if (Object.keys(listeners[event]).length === 0) {
                    delete listeners[event];
                }
            }
        }
    }
}
