import dayjs from "dayjs";

export default {
    methods: {
        formatDate(dateStr, format = 'DD MMM YYYY HH:mm') {
            return dayjs(dateStr).format(format)
        },
        formatSeconds(seconds) {
            return new Date(seconds * 1000).toISOString().slice(11, -1);
        },
        formatDiskSize(bytes) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

            let i = 0;

            for (i; bytes >= 1024 && i < (units.length - 1); i++) {
                bytes /= 1024;
            }

            return parseFloat(bytes).toFixed(2) + ` ${units[i]}`;
        }
    }
}
