<template>
    <div class="mb-6">
        <div>
            <h4 class="mb-0">{{ title }}</h4>
            <div v-if="subTitle" class="text-caption text-muted">{{ subTitle }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String,
        subTitle: String,
    },
    data() {
        return {};
    },
}
</script>
