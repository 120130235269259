<template>
    <div class="view-dcps">
        <generic-view-title title="DCPs"/>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="dcps"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/dcps/${item.id}`">{{ item.name }}</router-link>
            </template>

            <template v-slot:item.status="{item}">
                <dcp-status-badge :dcp="item"/>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary v-if="item.path" icon="IconCloudDownload" @click.prevent="initDcpDownload(item)" class="mr-2"/>
                <v-btn-secondary icon="IconEye" @click.prevent="view(item)" class="mr-2"/>
                <v-btn-secondary icon="IconTrash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import DcpStatusBadge from "../../Shared/Components/DcpStatusBadge.vue";
import dcpHelperMixin from "../../Plan/Mixins/dcpHelperMixin.js";
import echo from "../../Application/Services/echo.js";

export default {
    mixins: [paginationUrlMixin, dataFormatMixin, crudHelperMixin, dcpHelperMixin],
    components: {
        DcpStatusBadge,
        GenericViewTitle,
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'status', title: 'Progress'},
            {key: 'created_at', title: 'Started on', value: item => `${this.formatDate(item.created_at, 'DD MMM YYYY HH:mm:ss')}`},
            {key: 'finished_at', title: 'Finished on', value: item => `${item.workflow_log && item.workflow_log.finished_at ? this.formatDate(item.workflow_log.finished_at, 'DD MMM HH:mm:ss') : '...'}`},
            {key: 'seconds', title: 'Duration', value: item => `${item.workflow_log ? this.formatSeconds(item.workflow_log.seconds) : ''}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            team: this.$store.getters['team/current'],
        };
    },
    computed: {
        ...mapState('dcps', {
            dcps: types.DCPS,
            total: types.DCPS_TOTAL,
        }),
    },
    created() {
        this.workflowStepEvent = this.pusherSubscribe();
    },
    unmounted() {
        if (this.workflowStepEvent) {
            this.workflowStepEvent.unsubscribe();
        }
    },
    methods: {
        seedTable(options) {
            this.handlePagination('dcps/index', {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        view(dcp) {
            this.$router.push(`/dcps/${dcp.id}`);
        },
        destroy(campaign) {
            this.$store.dispatch('dcps/destroy', +campaign.id)
                .then(() => this.deletedMessage('DCP'))
                .catch(e => this.handleHttpError(e));
        },
        pusherSubscribe() {
            return echo.subscribe('workflow.job.step', (e, store) => {
                console.log(`Running event for dcp ${e.dcp_id}`);

                if (this.currentPage === 1) {
                    store.dispatch('dcps/index');
                } else if (this.dcps.filter(dcp => dcp.id === +e.dcp_id).length > 0) {
                    store.dispatch('dcps/get', {id: e.dcp_id, force: true}).then(() => this.$forceUpdate())
                }
            });
        }
    }
}
</script>
