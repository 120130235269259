<template>
    <div class="view-campaigns">
        <generic-view-title title="Campaigns">
            <div class="d-inline-block">
                <v-btn prepend-icon="spx:IconFolderPlus"
                       :disabled="createDisabled"
                       text="New"
                       @click="create"/>
                <v-tooltip v-if="createDisabled"
                           activator="parent"
                           location="bottom"
                           :text="createDisabledReason"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="campaigns"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/plan/campaigns/${item.id}`">{{ item.name }}</router-link>
                <em style="color: #888"> by {{ item.advertiser_name }}</em>
            </template>

            <template v-slot:item.profiles="{item}">
                <template v-for="(id, index) in item.output_profiles">
                    <template v-if="index > 0">; </template>
                    <router-link :to="`/plan/output-profiles/${id}`">{{
                        $store.getters['outputProfiles/profile'](id).name
                    }}</router-link>
                </template>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="IconEditCircle" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="IconTrash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericListItem from "../Components/OutputProfileListItem.vue";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import dataFormatMixin from "../../Shared/Mixins/dataFormatMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, dataFormatMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
        GenericListItem
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'profiles', title: 'Profiles'},
            //{key: 'state', title: 'Status'},
            //{key: 'source_id', title: 'Source ID'},
            {key: 'starts_on', title: 'Start', value: item => `${this.formatDate(item.starts_on, 'DD MMM YYYY')}`},
            {key: 'ends_on', title: 'End', value: item => `${this.formatDate(item.ends_on, 'DD MMM YYYY')}`},
            {key: 'created_at', title: 'Created on', value: item => `${this.formatDate(item.created_at)}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            createDisabled: false,
            createDisabledReason: '',
            team: this.$store.getters['team/current'],
        };
    },
    computed: {
        ...mapState('campaigns', {
            campaigns: types.CAMPAIGNS,
            total: types.CAMPAIGNS_TOTAL,
        }),
    },
    created() {
        this.allowCampaignCreation();
    },
    methods: {
        seedTable(options) {
            this.handlePagination('campaigns/index', {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        create() {
            this.$router.push('/plan/campaigns/create');
        },
        edit(campaign) {
            this.$router.push(`/plan/campaigns/${campaign.id}`);
        },
        destroy(campaign) {
            this.$store.dispatch('campaigns/destroy', +campaign.id)
                .then(() => this.deletedMessage('Campaign'))
                .catch(e => this.handleHttpError(e));
        },
        async allowCampaignCreation() {
            const outputProfilesCount = await this.$store.dispatch('outputProfiles/index');

            if (!outputProfilesCount.length) {
                this.createDisabled = true;
                this.createDisabledReason = 'Create an output profile first';
            }
        }
    }
}
</script>
