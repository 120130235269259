export default [
    {
        title: 'Dashboard',
        icon: 'IconHome',
        to: '/dashboard',
        ability: '*',
    },
    {
        title: 'DCPs',
        icon: 'IconMovie',
        to: '/dcps',
        ability: 'manage_mod_plan',
    },
    {
        title: 'Plan',
        icon: 'IconEditCircle',
        ability: 'manage_mod_plan',
        children: [
            {
                title: 'Campaigns',
                to: '/plan/campaigns',
                ability: 'manage_mod_plan',
            },
            /* {
                title: 'Advertisements',
                to: '/plan/advertisements',
                ability: 'manage_mod_plan',
            },*/
            {
                title: 'Output profiles',
                to: '/plan/output-profiles',
                ability: 'manage_mod_plan',
            }
        ]
    },
    {
        title: 'Team',
        icon: 'IconUsers',
        ability: 'manage_team',
        children: [
            /* {
                title: 'Notifications',
                to: '/notifications',
                ability: 'manage_team',
            }, */
            {
                title: 'Team Members',
                to: '/team/users',
                ability: 'manage_team',
            },
            {
                title: 'Settings',
                to: '/team/settings',
                ability: 'manage_team',
            },
            {
                title: 'Billing',
                href: '/billing/team/1',
                ability: 'manage_team',
            }
        ]
    },
    {
        title: 'Admin',
        icon: 'IconSettings',
        ability: 'manage_teams',
        children: [
            {
                title: 'Teams',
                to: '/admin/teams',
                ability: 'manage_teams',
            },
            {
                title: 'Users',
                to: '/admin/users',
                ability: 'manage_teams',
            },
        ]
    }
]
