<template>
    <v-row class="text-center h-100" align="center">
        <v-col>
            <v-icon icon="IconRouteX" size="x-large"/>
            <h2 class="mt-2">Page not found</h2>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {
    },
}
</script>
