<template>
    <h1 :class="'w-100 text-' + this.align">
        <span v-if="size === 'icon'" class="spx-logo-flare"></span>
        <span v-else>Sp&hairsp;<span class="spx-logo-flare"></span>&hairsp;ttix</span>
    </h1>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'normal',
        },
        align: {
            type: String,
            default: 'center',
        }
    },
    watch: {
        size(v) {
            this.showText = this.toggleText(v);
        }
    },
    data() {
        return {
            showText: this.toggleText(this.size)
        };
    },
    methods: {
        toggleText(size) {
            return {
                display: size === 'normal' ? 'icon' : 'none'
            }
        }
    }
}
</script>
