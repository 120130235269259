<template>
    <div class="pa-4">
        <h4 class="mt-2">{{ greeting() }}, <span class="font-weight-regular">{{ user.firstname }} {{ user.name }}</span></h4>

        <span class="text-subtitle-2 text-medium-emphasis">You're {{ user.roles.join(', ') }}</span>

        <v-divider class="my-4"></v-divider>

        <v-list class="pa-0">
            <!-- <v-list-item to="/notifications" exact prepend-icon="spx:IconEyeExclamation">
                <v-list-item-title class="text-subtitle-2">Notifications</v-list-item-title>
                <template v-slot:append>
                    <v-chip color="warning" class="text-white" :text="unreadNotificationCount" variant="flat" size="small" />
                </template>
            </v-list-item> -->

            <v-list-item to="/profile" exact prepend-icon="spx:IconUser">
                <v-list-item-title class="text-subtitle-2">Profile</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout()" prepend-icon="spx:IconLogout">
                <v-list-item-title class="text-subtitle-2">Logout</v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import store from "../store.js";
import router from "../Router/index.js";

export default {
    props: ['user'],
    computed: {
        unreadNotificationCount() {
            return this.$store.getters['notifications/unreadCount'].toString();
        }
    },
    methods: {
        logout() {
            store.dispatch('auth/logout').then(() => {
                if (router.currentRoute.name !== "login") {
                    router.push({ path: "/login" });
                }
            });
        },
        greeting() {
            const hour = (new Date()).getHours();

            let greeting = 'Good ';

            if (hour < 12) {
                greeting += 'morning';
            } else if (hour < 17) {
                greeting += 'afternoon';
            } else {
                greeting += 'evening';
            }

            return greeting;
        },
    }
}
</script>
