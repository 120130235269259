import types from "./storeTypes.js";
import constants from "../Application/constants.js";
import notificationClient from "./notificationClient.js";

const
    state = {
        [types.NOTIFICATIONS]: [],
        [types.NOTIFICATIONS_TOTAL]: 0,
        [types.NOTIFICATIONS_UNREAD_COUNT]: 0,
        [types.NOTIFICATIONS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.NOTIFICATIONS](state, data) {
            state[types.NOTIFICATIONS] = data.data;
            state[types.NOTIFICATIONS_TOTAL] = data.total || 0;
            state[types.NOTIFICATIONS_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
        [types.NOTIFICATIONS_UNREAD_COUNT](state, count) {
            state[types.NOTIFICATIONS_UNREAD_COUNT] = count
        }
    },
    getters = {
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {object}
         */
        page: state => state[types.NOTIFICATIONS_PAGE],
        /**
         * Return the current index view page.
         * @param {object} state
         * @return {object}
         */
        perPage: state => state[types.PAGINATION_PER_PAGE],
        /**
         * Return all notifications.
         * @param {object} state
         * @return {object}
         */
        notifications: state => state[types.NOTIFICATIONS],
        /**
         * Return the number of unread notifications.
         * @param {object} state
         * @return {number}
         */
        unreadCount: state => state[types.NOTIFICATIONS_UNREAD_COUNT],
    },
    helpers = {
        shouldFetchAnew(parameters, notifications) {
            return parameters.refresh
                || parameters.sortBy
                || parameters.page !== state[types.NOTIFICATIONS_PAGE]
                || parameters.perPage !== state[types.PAGINATION_PER_PAGE]
                || !notifications.length;
        },
    },
    actions = {
        /**
         * Verify if index page should be requested from server.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<object|null>}
         */
        index({commit, getters}, parameters = {}) {
            const notifications = getters.notifications;

            parameters.page = parameters.page || getters.page;
            parameters.perPage = parameters.perPage || getters.perPage;

            if (!helpers.shouldFetchAnew(parameters, notifications)) {
                return Promise.resolve(notifications);
            }

            return notificationClient
                .getNotifications(parameters.page, parameters.perPage)
                .then(response => {
                    commit(types.NOTIFICATIONS, response.data);

                    return response.data.data;
                });
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} commit
         * @param {function} dispatch
         * @param {string} uuid
         * @param {boolean} read
         * @return {Promise<object>}
         */
        async read({commit, dispatch}, {uuid, read}) {
            const response = await notificationClient.updateNotification(uuid, {read: !!read})
                .then(response => commit(types.NOTIFICATIONS_UNREAD_COUNT, response.data.data));

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * @param {function} commit
         * @param {number} count
         */
        setUnreadCount({commit}, count) {
            commit(types.NOTIFICATIONS_UNREAD_COUNT, count);
        },
        /**
         * Delete a resource and fetch the current list again.
         * @param {function} dispatch
         * @param {object} model
         * @return {Promise<object|null>}
         */
        destroy({dispatch}, model) {
            return notificationClient
                .deleteNotification(model)
                .then(() => dispatch('index', {refresh: true}));
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
