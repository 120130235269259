export default function permission({ store }, permission) {
    const can = store.getters['auth/userCan'];

    if (can(permission)) {
        return true;
    }

    return {
        name: '401'
    };
}
